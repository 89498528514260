/** @jsx jsx */
import { Link } from "gatsby"
import { jsx } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div
      sx={{
        p: `200px 15px 0 15px`,
        width: ["95%", "95%", "80%"],
        maxWidth: `1400px`,
        margin: `0 auto`,
      }}
    >
      <h1>Not Found</h1>
      <p>We couldn't find the page you're looking for.</p>
      <Link to="/">
        <button>Go home</button>
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
